import '~/libraries/jquery'

import '~/libraries/nprogress'

import Turbolinks from 'turbolinks'
Turbolinks.start()

import Rails from '@rails/ujs'
Rails.start()

import '~/libraries/fomantic_ui/semantic.less'
import '~/libraries/fomantic_ui/semantic'

// When a link starting with # is clicked, scroll down the page to the corresponding ID
$(document).on('click', 'a[href*="#"]:not([href="#"])', function() {
	if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
		var target = $(this.hash);
		target = target.length ? target : $('[name=' + this.hash.slice(1) +']');

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 1000);
			return false;
		}
	}
});
// Open / Close the login area
$(document).on('click', 'header .login a, header .close a', function(event) {
	event.preventDefault();

	// Open preheader login area
	$('#login-form').slideToggle();

	// Swap "Login" for "Close"
	$('header nav .login').toggleClass('hide');
	$('header nav .close').toggleClass('hide');
});

// Display / Hide FAQ on mobile
$(document).on('click', '#faqs .faq h5', function() {

	// Only apply if 768px+
	if ( $(window).width() < 768) {
		$(this).siblings().toggle();
	};

});

// Change testimonial based on teacher's name clicked
$(document).on('click', '.testimonial-buttons a', function(event) {
	event.preventDefault();

	// Get ID of teacher clicked
	var selected_link = $(this).attr("ID");

	// Replace the active testimonail with the selected one
	$('#teacher-testimonials .testimonial').removeClass('active');
	$('#teacher-testimonials #' + selected_link ).addClass('active');

	// Update which link is active
	$('.testimonial-buttons a').removeClass('active');
	$(this).addClass('active');

});

// Read more links on testimonials
$(document).on('click', '.testimonial a', function(event) {
	event.preventDefault();

	$(this).hide();
	$(this).siblings('span').fadeIn();
});

$(document).on("turbolinks:load", function() {
	new WOW().init();

	$('.ui.login.form').form();
});
